import env from '@engine/env'

export default async function getIntersection(
  { latitude, longitude }: { latitude: number; longitude: number},
  setLoading?: (loading: boolean) => void
) {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), 1500);
  const { intersection } = await fetch(
    `https://secure.geonames.org/findNearestIntersectionJSON?lat=${latitude}&lng=${longitude}&username=${env().REACT_APP_GEONAMES_USERNAME}`,
    { signal: controller.signal }
  ).then(r => {
    setLoading?.(false);
    return r.json();
  })
  clearTimeout(id);
  return intersection
}
