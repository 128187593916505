import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { TextField, InputAdornment, CircularProgress, Tooltip } from '@material-ui/core';
import { Switch } from '@components/common';
import { initAutocomplete } from '@utility/autocomplete';
import { offlineConfig } from '@engine/dependencies/localforage';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { locate, coordsToAddress } from '@utility/location';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { LastRoadblockOrDuiLocation } from '@engine/ducks/types';
import { Props, connector } from './props';
import './RipaLocationForm.scss';

const MIN_ACCURACY = 50; // Meters

type SchoolT = NonNullable<typeof window.schools>[0];
type CityT = NonNullable<typeof window.cities>[0];

const RipaLocationForm = ({
  setLocation,
  location,
  errors,
  setSchool,
  setCoordinates,
  school,
  stopHappenedAtPublicSchool,
  setStopHappenedAtPublicSchool,
  city,
  setCity,
  watchLocation,
  userState,
  stopDateTime,
  assignment,
  officerAssignment,
  useLastLocation,
  setUseLastLocation,
}: Props) => {
  const [fieldValue, setFieldValue] = useState(location ?? '');
  const [locationEnabled, setLocationEnabled] = useState(watchLocation.available);
  const [latitude, setLatitude] = useState(watchLocation.latitude);
  const [longitude, setLongitude] = useState(watchLocation.longitude);
  const [accuracy, setAccuracy] = useState(watchLocation.accuracy);
  const [useCurrentLoc, setUseCurrentLoc] = useState(false);
  const [isWorkingOffline, setIsWorkingOffline] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);
  const [lastRoadblockOrDuiLocation, setLastRoadblockOrDuiLocation] = useState<LastRoadblockOrDuiLocation | null>(null)
  const officerAssignmentVal = officerAssignment?.OfficerTypeofAssignment?.possibleValues;
  const roadblockDUISobrietyCheckpoint = officerAssignmentVal?.RoadblockDUISobrietyCheckpoint.value;
  const isRoadblockDUISobrietyCheckpoint = assignment === roadblockDUISobrietyCheckpoint;

  const handleSchoolOption = (switchValue: boolean) => {
    setStopHappenedAtPublicSchool(switchValue);
    setUseCurrentLoc(false);
    if (!switchValue) {
      setSchool('');
    }
  };

  const setQueriedLocation = (q_street: string, q_city: string) => {
    setFieldValue(q_street);
    setLocation(q_street);
    setCity(q_city);
  };

  const handleUseLocation = async (v: boolean) => {

    if (locationEnabled === 'available' && v) {
      setLocationLoading(true);
      setUseCurrentLoc(v);
      setCoordinates({ latitude, longitude, accuracy });
      await coordsToAddress({ latitude, longitude }, setQueriedLocation, setLocationLoading);
    }
    if (useCurrentLoc) {
      setUseCurrentLoc(false);
      setCoordinates({});
    }
  };
  const handleSetSchoolName = (schoolName?: string) => {
    setSchool(schoolName);
  };
  const handleSetSchool = (selSchool: SchoolT | null) => {
    handleSetSchoolName(selSchool?.School);

    if (selSchool?.School) {
      const { Street, City, State, Zip } = selSchool;
      const addressTrim = `${Street || ''}${Street ? ',' : ''} ${City || ''}${City ? ',' : ''} ${State || ''} ${Zip || ''}`.trim();
      const cityTrim = (City || '')?.trim();
      if (addressTrim) {
        setLocation(addressTrim);
        setFieldValue(addressTrim);
      }
      if (cityTrim) {
        setCity(cityTrim);
      }
      setUseCurrentLoc(false);
    }
  };

  useEffect(() => {
    if (locationEnabled === 'unavailable') {
      setUseCurrentLoc(false);
    }
  }, [locationEnabled]);

  useEffect(() => {
    const runInit = async () => {
      const workOfflineStatus = await offlineConfig.getItem('work-offline').then((wo) => wo === 'true');
      setIsWorkingOffline(workOfflineStatus);
      if (!workOfflineStatus) {
        if (userState) {
          initAutocomplete('ripa-location-form-autocomplete', userState, (loc, ci) => {
            setFieldValue(loc);
            setLocation(loc);
            setCity(ci);
          });
        }
        locate(setLocationEnabled, setAccuracy, setLatitude, setLongitude);
      }
    };
    runInit();
    const lastLocation = JSON.parse(localStorage.getItem('lastRoadblockOrDuiLocation') ?? 'null')
    if (lastLocation) setLastRoadblockOrDuiLocation(lastLocation);
  }, []);

  const schoolFilterOptions = createFilterOptions<SchoolT>({
    matchFrom: 'any',
    limit: 100,
  });

  const cityFilterOptions = createFilterOptions<CityT>({
    matchFrom: 'any',
    limit: 50,
  });
  const toggleUseLastLocation = (use:boolean) => {
    setUseLastLocation(use);
    if (use && lastRoadblockOrDuiLocation) {
      if (lastRoadblockOrDuiLocation.stopHappenedAtPublicSchool) {
        setSchool(lastRoadblockOrDuiLocation.school);
      }
    setStopHappenedAtPublicSchool(lastRoadblockOrDuiLocation.stopHappenedAtPublicSchool);
    setCity(lastRoadblockOrDuiLocation.city);
    setFieldValue(lastRoadblockOrDuiLocation.location);
    setLocation(lastRoadblockOrDuiLocation.location);

    } else {
      setStopHappenedAtPublicSchool(false);
      setSchool('');
      setCity('');
      setFieldValue('');
      setLocation('');

    }
    setUseCurrentLoc(false);
    setCoordinates({});
}

  return (
    <div className="ripa-location-form" data-testid="ripa-location-form">
      <div className="ripa-location-form__title">{stopHappenedAtPublicSchool ? 'What was the school and location?' : 'Where was the location?'}</div>
      <div className="ripa-location-form__switch-container">
        <div className={cn('ripa-location-form__switch school', { 'no-school-switch': window?.schools?.length === 0 })}>
          <div className="ripa-location-form__switch-mobile" />
          <Switch
            className="ripa-location-form__school-picker-toggle-switch"
            data-testid="ripa-location-form-school-switch"
            onText="YES"
            offText="NO"
            value={!!stopHappenedAtPublicSchool}
            onChange={handleSchoolOption}
          />
          <div className="ripa-location-form__school-switch-text">STOP HAPPENED AT K-12 PUBLIC SCHOOL</div>
        </div>
        <div
          className={cn('ripa-location-form__switch', {
            searching: useCurrentLoc && accuracy > MIN_ACCURACY,
            active: useCurrentLoc && locationEnabled && accuracy <= MIN_ACCURACY,
            disabled: ['unavailable', 'unknown'].includes(locationEnabled),
            waiting: locationEnabled === 'unknown',
            unavailable: locationEnabled === 'unavailable',
          })}
        >
          <div className="ripa-location-form__current-loc-switch-text">
            {isWorkingOffline
              ? 'OFFLINE'
              : useCurrentLoc && locationEnabled === 'available' && accuracy <= MIN_ACCURACY
                ? 'CURRENT LOCATION'
                : useCurrentLoc && accuracy > MIN_ACCURACY
                  ? 'SEARCHING LOCATION'
                  : locationEnabled === 'unknown'
                    ? 'WAITING FOR GPS'
                    : locationEnabled === 'unavailable'
                      ? 'NO LOCATION'
                      : 'CURRENT LOCATION'}
          </div>
          <Switch
            className={cn('ripa-location-form__location-picker-toggle-switch', {
              disabled: ['unavailable', 'unknown'].includes(locationEnabled),
            })}
            data-testid="ripa-location-form-location-switch"
            aria-label="shows if gps location is available"
            onText="YES"
            offText="NO"
            disabled={['unavailable', 'unknown'].includes(locationEnabled) || isWorkingOffline}
            value={useCurrentLoc}
            onChange={handleUseLocation}
          />
          <div className="ripa-location-form__switch-mobile" />
        </div>
      </div>
      {lastRoadblockOrDuiLocation && isRoadblockDUISobrietyCheckpoint && (
        <div className="ripa-location-form__switch-container">
          <div className="ripa-location-form__switch school">

            <div className="ripa-location-form__switch-mobile" />
              <Switch
                className={cn('ripa-location-form__location-picker-toggle-switch')}
                data-testid="ripa-location-form-last-location-switch"
                aria-label="shows if assignment is at roadblock or DUI checkpoint"
                onText="YES"
                offText="NO"
                value={useLastLocation || false}
                onChange={toggleUseLastLocation}
                disableChangeOnMount
              />

              <div className="ripa-location-form__school-switch-text">REUSE LAST LOCATION</div>
            </div>

        </div>
      )}
      {stopHappenedAtPublicSchool && (
        <Autocomplete<SchoolT>
          className="ripa-location-form__school-autocomplete"
          data-testid="ripa-location-form-school-autocomplete"
          classes={{ option: 'school' }}
          defaultValue={window.schools?.find(
            (s) =>
              school && city
              && s.School.localeCompare(school, 'en', { sensitivity: 'base' }) === 0
              && (s.City?.localeCompare(city, 'en', { sensitivity: 'base' }) ?? 0) === 0
          )}
          onChange={(_, o) => handleSetSchool(o)}
          blurOnSelect={true}
          getOptionDisabled={(option) => option.StatusType === 'Closed'}
          getOptionLabel={(option) => option.School}
          renderOption={(option) => (
            <>
              {option.StatusType === 'Closed' ? (
                <div>
                  <Tooltip title="School is closed and cannot be selected">
                    <span data-status={option.StatusType}>
                      {option.School}
                      {` ${option.Street || ''} ${option.City || ''}`}
                    </span>
                  </Tooltip>
                </div>
              ) : (
                <span data-status={option.StatusType}>
                  {option.School}
                  {` ${option.Street || ''} ${option.City || ''}`}
                </span>
              )}
            </>
          )}
          options={window.schools ?? []}
          filterOptions={schoolFilterOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              error={get(errors, 'school', 0) > 0}
              placeholder="EG: Lincoln Elementary School..."
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    <div className="ripa-location-form__address-input-school-end-adornment-default">{params.InputProps.endAdornment}</div>
                    <InputAdornment position="end" className="ripa-location-form__address-input-school-end-adornment-school">
                      <div className="ripa-location-form__address-input-school">SCHOOL</div>
                    </InputAdornment>
                  </>
                ),
              }}
              inputProps={{
                ...params.inputProps,
                'data-testid': 'ripa-location-form-school-autocomplete-input',
              }}
            />
          )}
        />
      )}
      <TextField
        className="ripa-location-form__address-input"
        placeholder="Enter block number, intersection, highway exit, etc..."
        variant="outlined"
        value={fieldValue}
        error={get(errors, 'location', 0) > 0}
        onChange={({ target: { value } }) => {
          setFieldValue(value);
          setLocation(value);
          setUseCurrentLoc(false);
          setCoordinates({});
        }}
        InputProps={{
          inputProps: {
            'data-testid': 'ripa-location-form-address-input',
            id: 'ripa-location-form-autocomplete',
            onChange: ({ target: { value } }: any) => {
              setFieldValue(value);
              setLocation(value);
              setUseCurrentLoc(false);
            },
          },
          endAdornment: (
            <InputAdornment position="end">
              {locationLoading ? (
                <CircularProgress />
              ) : (
                <div
                  className={cn('material-icons', {
                    disabled: !useCurrentLoc || ['unavailable', 'unknown'].includes(locationEnabled),
                  })}
                >
                  place
                </div>
              )}
            </InputAdornment>
          ),
        }}
      />
      <Autocomplete<CityT>
        inputValue={city}
        className="ripa-location-form__city-autocomplete"
        data-testid="ripa-location-form-city-autocomplete"
        defaultValue={window.cities?.find((c) => c.City.localeCompare(city as string, 'en', { sensitivity: 'base' }) === 0)}
        onInputChange={(_, o) => setCity(o)}
        blurOnSelect={true}
        getOptionLabel={(option) => option.City}
        options={window.cities ?? []}
        filterOptions={cityFilterOptions}
        renderOption={c => (
          <>
            {c.InactiveDate && dayjs(c.InactiveDate).isBefore(stopDateTime) ? (
              <Tooltip title={`City inactive as of ${c.InactiveDate}`}>
                <span className="ripa-location-form__city-autocomplete-option-inactive">
                  {c.City}
                </span>
              </Tooltip>
            ) : (
              <span className="ripa-location-form__city-autocomplete-option-active">
                {c.City}
              </span>
            )}
          </>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            error={get(errors, 'city', 0) > 0}
            placeholder="Enter city..."
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  <div className="ripa-location-form__address-input-city-end-adornment-default">{params.InputProps.endAdornment}</div>
                  <InputAdornment position="end" className="ripa-location-form__address-input-city-end-adornment-city">
                    <div className="ripa-location-form__address-input-city">CITY</div>
                  </InputAdornment>
                </>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              'data-testid': 'ripa-location-form-city-autocomplete-input',
            }}
          />
        )}
      />
    </div>
  );
};

const Helper = () => (
  <div className="ripa-form-container__helper-box">
    <div className="material-icons">help</div>
    <div className="ripa-form-container__helper-box-text">
      <b>{'Report Location as: '}</b>
      Block number and street name; or closest intersection; or highway and closest highway exit. If none of these are applicable, please report a road marker, landmark, or other description. Do not
      provide the exact address of a residence. Otherwise report county.
      <br />
    </div>
  </div>
);

RipaLocationForm.helper = Helper;

export default connector(RipaLocationForm);
